@font-face {
  font-family: "SafePlace";
  src: local("SafePlace"),
    url("./fonts/SafePlace/ASafePlacetoFall.ttf") format("truetype");
}

html,
body {
  height: 100%;
}

a:visited {
  color: inherit;
}

#root {
  font-family: "SafePlace";
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: hidden;
  background-image: url("./assets/green-bg.png");
  background-size: cover;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: relative;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
}

.app {
  margin-top: -3em;
  overflow: hidden;
  min-height: 0;
  display: flex;
}

.app > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  position: relative;
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
  width: 500px;
}

.currentlySelectedCard {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.cardContainer {
  width: 90vw;
  max-width: 500px;
  height: 500px;
}

.hideCardContainer {
  display: none;
}

.hex {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 433px;
  height: 433px;
  box-sizing: border-box;
}

.hex img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.hex-background {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 433px;
  height: 433px;
}

.hexagon {
  overflow: hidden;
  visibility: hidden;
}

.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.hexagon1 {
  width: 500px;
  height: 500px;
  margin: 0 0 0 -80px;
}

.slide-effect p {
  font-size: 25px;
  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -ms-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;
  animation: signup-response 0.5s 1;
  -webkit-animation: signup-response 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-effect {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.card {
  position: relative;
  width: 90vw;
  max-width: 500px;
  height: 90vw;
  max-height: 500px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  height: 28px;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 2em auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 999em;
  border: none;
  color: #fff;
  font-family: "SafePlace";
  font-size: 18px;
  background-color: #8cc640;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 140px;
}

.buttons button:hover {
  transform: scale(1.05);
}

.cta-bottom {
  display: none;
}

.cta-top {
  position: absolute;
  top: 0;
  right: 0;
}

.cta-container {
  justify-content: right;
  padding: 0 5px;
}

.cta-container a {
  margin: 0 10px;
  padding: 5px;
  justify-content: right;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  color: #1071b9;
  font-family: "SafePlace";
  font-size: 24px;
  display: inline-block;
  font-weight: bold;
  text-shadow: 1px 1px 1px #efeff0;
}

.cta-container a:hover {
  transition: 0.3s;
}

.logo {
  width: 70%;
  max-width: 350px;
  position: relative;
  margin-bottom: 2em;
}

.gif {
  width: 100%;
  position: relative;
  -webkit-box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44);
  box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44);
  margin-bottom: 10px;
}

.finished-text {
  position: relative;
  color: #1071b9;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #efeff0;
}

.shuffle_button {
  background-color: #903f97 !important;
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

* {
  touch-action: manipulation;
}

/* Hamburger Menu Styles */
.hamburger-menu {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}

.hamburger-icon {
  width: 30px;
  height: 24px;
  position: relative;
  transition: 0.3s;
}

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #903f97;
  border-radius: 3px;
  transition: 0.3s;
}

.hamburger-icon span:nth-child(1) {
  top: 0;
}
.hamburger-icon span:nth-child(2) {
  top: 10px;
}
.hamburger-icon span:nth-child(3) {
  top: 20px;
}

.hamburger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(8px, 8px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

/* Dropdown Styles */
.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  color: #1071b9;
  font-family: "SafePlace";
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;
  text-shadow: 1px 1px 1px #efeff0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-arrow {
  font-size: 20px;
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}

.dropdown-content a {
  color: #1071b9;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.2s;
}

.dropdown-content a:hover {
  background-color: rgba(16, 113, 185, 0.1);
}

/* Mobile Menu Styles */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  width: 300px;
  background: rgb(16, 113, 185);
  flex-direction: column;
  padding-top: 80px;
  transition: 0.3s;
  z-index: 999;
}

.mobile-menu a {
  color: #fff;
}

.mobile-menu.menu-open {
  right: 0;
}

.menu-section {
  margin-bottom: 20px;
}

.menu-header {
  color: #fff;
  font-family: "SafePlace";
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  text-align: left;
}

.menu-option {
  padding-left: 35px !important;
  font-size: 16px !important;
}

.mobile-menu a {
  padding-left: 10px;
  color: #fff;
  font-family: "SafePlace";
  font-size: 18px;
  text-align: left;
  text-decoration: none;
  font-weight: bold;
  display: block;
}

@media screen and (max-width: 975px) {
  .cta-container a,
  .dropdown-button {
    font-size: 19px;
  }
}

@media screen and (max-width: 730px) {
  .hamburger-menu {
    display: block;
  }

  .mobile-menu {
    display: flex;
  }

  .cta-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 0 5px;
    text-align: left;
    width: 100%;
    margin-bottom: 2em;
  }

  .cta-bottom {
    display: none;
  }

  .cta-container a {
    font-size: 16px;
  }

  .buttons button {
    width: 95px;
  }

  .slide-effect p {
    font-size: 18px;
  }

  .cta-top {
    display: none;
  }

  .logo {
    margin-top: 2em;
  }

  .hex {
    width: 300px;
    height: 300px;
  }

  .hex-background {
    width: 300px;
    height: 300px;
  }

  .card {
    max-width: 300px;
    max-height: 300px;
  }

  .cardContainer {
    max-width: 300px;
    max-height: 300px;
  }

  .swipe {
    width: 300px;
  }
}
